import React from 'react';
import {Create, Datagrid, DateField, Edit, EditButton, List, required, SimpleForm,} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";
import LinkTextField from "../components/LinkTextField";
import {JsonInput} from "react-admin-json-view";


export const AgricultureAgroModelList = (props) => (
    <List {...props} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <LinkTextField id="agriculture_id" link="agriculture" label="Культура" source="agriculture.name"/>
            <LinkTextField id="agro_model_id" link="agro_model" label="Модель" source="agro_model.name"/>
            <EditButton basePath="/agro_model_company"/>
        </Datagrid>
    </List>
);

export const AgricultureAgroModelCreate = (props) => (
    <Create title="Связать культуру и модель" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Модель" validate={required()} source="agro_model_id" fetchRel={"agro_model"}
                              optionText={"name"}/>
            <AwaitSelectInput label="Культура" validate={required()} source="agriculture_id" optionText="name"
                              fetchRel={"agriculture"}/>
            <JsonInput
                label="Конфигурация"
                source="config"
                validate={[required()]}
                jsonString={true} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </SimpleForm>
    </Create>
);

export const AgricultureAgroModelEdit = (props) => (
    <Edit title={"Изменить связь"} {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Модель" validate={required()} source="agro_model_id" fetchRel={"agro_model"}
                              optionText={"name"}/>
            <AwaitSelectInput label="Культура" validate={required()} source="agriculture_id" optionText="name"
                              fetchRel={"agriculture"}/>
            <JsonInput
                label="Конфигурация"
                source="config"
                validate={[required()]}
                jsonString={true} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </SimpleForm>
    </Edit>
);
