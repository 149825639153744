import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    EmailField,
    List,
    PasswordInput,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";
import LinkTextField from "../components/LinkTextField";


export const UserList = (props) => (
    <List {...props} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Фамилия" source="surname"/>
            <TextField label="Логин" source="username"/>
            <LinkTextField id="company_id" link="company" label="Компания" source="company.name"/>
            <DateField label="Последний вход" source="last_visit"/>
            <ShowButton/>
            <EditButton basePath="/user"/>
        </Datagrid>
    </List>
);

export const UserCreate = (props) => (
    <Create title="Новый пользователь" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Фамилия" source="surname"/>
            <TextInput label="Имя" source="name"/>
            <TextInput label="Отчество" source="patronymic"/>
            <TextInput label="Логин" source="username" validate={required()}/>
            <PasswordInput label="Пароль" source="password" validate={required()}/>
            <TextInput label="Почта" source="email" validate={required()}/>
            <TextInput label="Телефон" source="phone_number"/>
            <TextInput label="Должность" source="profession"/>
            <BooleanInput label="Администратор" source="is_admin" defaultValue={false} validate={required()}/>
            <BooleanInput label="Уведомлять на почту" source="alerts_allowed" defaultValue={false} validate={required()}/>
            <AwaitSelectInput label="Компания" validate={required()} source="company_id" optionText="name"
                              fetchRel={"company"}/>
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit title={"Изменить пользователя"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Фамилия" source="surname"/>
            <TextInput label="Имя" source="name"/>
            <TextInput label="Отчество" source="patronymic"/>
            <TextInput label="Логин" source="username" validate={required()}/>
            <TextInput label="Почта" source="email" validate={required()}/>
            <TextInput label="Телефон" source="phone_number"/>
            <TextInput label="Должность" source="profession"/>
            <BooleanInput label="Администратор" source="is_admin" validate={required()}/>
            <BooleanInput label="Уведомлять на почту" source="alerts_allowed" defaultValue={false} validate={required()}/>
            <AwaitSelectInput label="Компания" validate={required()} source="company_id" optionText="name"
                              fetchRel={"company"}/>
        </SimpleForm>
    </Edit>
);

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <DateField label="Создан" source="created_at"/>
            <DateField label="Изменён" source="updated_at"/>
            <TextField label="Фамилия" source="surname"/>
            <TextField label="Имя" source="name"/>
            <TextField label="Отчество" source="patronymic"/>
            <TextField label="Логин" source="username"/>
            <EmailField label="Почта" source="email"/>
            <TextField label="Телефон" source="phone_number"/>
            <TextField label="Должность" source="profession"/>
            <BooleanField label="Администратор" source="is_admin"/>
            <BooleanField label="Уведомления на почту" source="alerts_allowed"/>
            <LinkTextField id="company_id" link="company" label="Компания" source="company.name"/>
            <DateField label="Последний вход" showTime={true} source="last_visit"/>
            <DateField label="Последний логин" showTime={true} source="last_login"/>
        </SimpleShowLayout>
    </Show>
);

