import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import LinkTextField from "../components/LinkTextField";
import {AwaitSelectInput} from "../components/AwaitSelectInput";


export const AgricultureSortList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <LinkTextField id="agriculture_id" link="agriculture" label="Культура" source="agriculture.name"/>
            <TextField label="Сорт" source="name"/>
            <EditButton basePath="/agriculture_sort"/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const AgricultureSortCreate = (props) => (
    <Create title="Новый сорт культуры" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Культура" source="agriculture_id" optionText="name" fetchRel={"agriculture"}/>
            <TextInput label="Название сорта" validate={required()} source="name"/>
            <NumberInput label="Температура начала роста" source="start_growing_t"/>
            <NumberInput label="Температура созревания" source="end_growing_t"/>
            <NumberInput label="Потребность в тепле" source="need_for_heat"/>
        </SimpleForm>
    </Create>
);

export const AgricultureSortEdit = (props) => (
    <Edit title={"Изменить сорт культуры"} {...props}>
        <SimpleForm>
            <AwaitSelectInput label="Культура" source="agriculture_id" optionText="name" fetchRel={"agriculture"}/>
            <TextInput label="Название" validate={required()} source="name"/>
            <NumberInput label="Температура начала роста" source="start_growing_t"/>
            <NumberInput label="Температура созревания" source="end_growing_t"/>
            <NumberInput label="Потребность в тепле" source="need_for_heat"/>
        </SimpleForm>
    </Edit>
);

export const AgricultureSortShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <LinkTextField id="agriculture_id" link="agriculture" label="Культура" source="agriculture.name"/>
            <TextField label="Название сорта" source="name"/>
            <NumberField label="Температура начала роста" source="start_growing_t"/>
            <NumberField label="Температура созревания" source="end_growing_t"/>
            <NumberField label="Потребность в тепле" source="need_for_heat"/>
        </SimpleShowLayout>
    </Show>
);
