import React from 'react';
import {Create, Datagrid, DateField, Edit, EditButton, List, required, SimpleForm,} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";
import LinkTextField from "../components/LinkTextField";


export const AgriculturePestList = (props) => (
    <List {...props} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <LinkTextField id="agriculture_id" link="agriculture" label="Культура" source="agriculture.name"/>
            <LinkTextField id="pest_id" link="pest" label="Вредитель" source="pest.name"/>
            <EditButton basePath="/agriculture_pest"/>
        </Datagrid>
    </List>
);

export const AgriculturePestCreate = (props) => (
    <Create title="Связать компанию и культуру" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Культура" validate={required()} source="agriculture_id" fetchRel={"agriculture"}
                              optionText={"name"}/>
            <AwaitSelectInput label="Вредитель" validate={required()} source="pest_id" optionText="name"
                              fetchRel={"pest"}/>
        </SimpleForm>
    </Create>
);

export const AgriculturePestEdit = (props) => (
    <Edit title={"Изменить связь"} {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Культура" validate={required()} source="agriculture_id" fetchRel={"agriculture"}
                              optionText={"name"}/>
            <AwaitSelectInput label="Вредитель" validate={required()} source="pest_id" optionText="name"
                              fetchRel={"pest"}/>
        </SimpleForm>
    </Edit>
);
