import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";


export const ZondMeasurementList = (props) => (
    <List {...props} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Столбец" source="column"/>
            <TextField label="Описание" source="description"/>
            <TextField label="Единица измерения" source="measurement_unit"/>
            <NumberField label="Тип устройства" source="zond_type.type"/>
            <NumberField label="Тип измерения" source="zond_measurement_type.type"/>
            <EditButton basePath="/zond_measurement"/>
        </Datagrid>
    </List>
);

export const ZondMeasurementCreate = (props) => (
    <Create title="Новое измерение" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Столбец в БД" validate={required()} source="column"/>
            <TextInput label="Описание метрики" validate={required()} source="description"/>
            <TextInput label="Единица измерения" validate={required()} source="measurement_unit"/>
            <AwaitSelectInput label="Тип устройства" validate={required()} source="zond_type_id" optionText="type"
                              fetchRel={"zond_type"}/>
            <AwaitSelectInput label="Тип измерения" validate={required()} source="zond_measurement_type_id"
                              optionText="type" fetchRel={"zond_measurement_type"}/>
        </SimpleForm>
    </Create>
);

export const ZondMeasurementEdit = (props) => (
    <Edit title={"Изменить измерение"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Столбец в БД" validate={required()} source="column"/>
            <TextInput label="Описание метрики" validate={required()} source="description"/>
            <TextInput label="Единица измерения" validate={required()} source="measurement_unit"/>
            <AwaitSelectInput label="Тип устройства" validate={required()} source="zond_type_id" optionText="type"
                              fetchRel={"zond_type"}/>
            <AwaitSelectInput label="Тип измерения" validate={required()} source="zond_measurement_type_id"
                              optionText="type" fetchRel={"zond_measurement_type"}/>
        </SimpleForm>
    </Edit>
);
