import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import LinkTextField from "../components/LinkTextField";


export const WikiSectionList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Описание" source="description"/>
            <LinkTextField id="root_section_id" link="wiki_section" label="Родитель" source="root_section.name"/>
            <ShowButton/>
            <EditButton basePath="/wiki_section"/>
        </Datagrid>
    </List>
);

export const WikiSectionCreate = (props) => (
    <Create title="Новый раздел" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Описание" validate={required()} source="description"/>
            <AwaitSelectInput label="Родительский раздел" source="root_section_id"
                              optionText="name" fetchRel={"wiki_section"}/>
        </SimpleForm>
    </Create>
);

export const WikiSectionEdit = (props) => (
    <Edit title={"Изменить раздел"} {...props}>
        <SimpleForm>
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Описание" validate={required()} source="description"/>
            <AwaitSelectInput label="Родительский раздел" source="root_section_id"
                              optionText="name" fetchRel={"wiki_section"}/>
        </SimpleForm>
    </Edit>
);

export const WikiSectionShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Описание" source="description"/>
            <LinkTextField id="root_section_id" link="wiki_section" label="Родитель" source="root_section.name"/>
        </SimpleShowLayout>
    </Show>
);
