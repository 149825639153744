import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";


export const ZondNotificationList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <TextField label="Устройство" source="zond.number_in_db"/>
            <TextField label="Оповещение" source="notification.title"/>
            <TextField label="Дополнительно" source="additional_info"/>
            <BooleanField label="Просмотрено" source="is_checked"/>
            <EditButton basePath="/zond_notification"/>
        </Datagrid>
    </List>
);

export const ZondNotificationCreate = (props) => (
    <Create title="Новое оповещение для устройства" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Номер устройства" validate={required()} source="zond_id" optionText="number"
                              fetchRel={"zond"}/>
            <AwaitSelectInput label="Оповещение" validate={required()} source="notification_id" optionText="text"
                              fetchRel={"notification"}/>
            <TextInput label="Дополнительная информация" source="additional_info"/>
            <BooleanInput label="Прочитано" source="is_checked" defaultValue={false} validate={required()}/>
        </SimpleForm>
    </Create>
);

export const ZondNotificationEdit = (props) => (
    <Edit title={"Изменить оповещение для устройства"} {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Номер устройства" validate={required()} source="zond_id" optionText="number"
                              fetchRel={"zond"}/>
            <AwaitSelectInput label="Оповещение" validate={required()} source="notification_id" optionText="text"
                              fetchRel={"notification"}/>
            <TextInput label="Дополнительная информация" source="additional_info"/>
            <BooleanInput label="Прочитано" source="is_checked" defaultValue={false} validate={required()}/>
        </SimpleForm>
    </Edit>
);
