import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


export const NotificationTypeList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Тип оповещения" source="type"/>
            <TextField label="Описание" source="description"/>
            <TextField label="Уровень" source="alert"/>
            <EditButton basePath="/notification_type"/>
        </Datagrid>
    </List>
);

export const NotificationTypeCreate = (props) => (
    <Create title="Новый тип оповещения" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Тип оповещения" validate={required()} source="type"/>
            <TextInput label="Уровень" source="alert"/>
            <TextInput multiline label="Описание" source="description"/>
        </SimpleForm>
    </Create>
);

export const NotificationTypeEdit = (props) => (
    <Edit title={"Изменить тип оповещения"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Тип оповещения" validate={required()} source="type"/>
            <TextInput label="Уровень" source="alert"/>
            <TextInput multiline label="Описание" source="description"/>
        </SimpleForm>
    </Edit>
);
