import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';


export const AgroModelList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Название" source="name"/>
            <TextField label="Короткое описание" source="short_description"/>
            <TextField label="Ссылка" source="link"/>
            <BooleanField label="Включена" source="is_enabled"/>
            <BooleanField label="Общая" source="is_common"/>
            <EditButton basePath="/agro_model"/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const AgroModelCreate = (props) => (
    <Create title="Новая модель" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Ссылка" validate={required()} source="link"/>
            <TextInput label="Короткое описание" validate={required()} source="short_description"/>
            <TextInput multiline label="Полное описание" validate={required()} source="long_description"/>
            <TextInput label="Текст для выключенной модели" source="disabled_text"/>
            <BooleanInput label="Включена" validate={required()} defaultValue={true} source="is_enabled"/>
            <BooleanInput label="Общая" validate={required()} defaultValue={false} source="is_common"/>
            <BooleanInput label="Работает для зондов" validate={required()} defaultValue={false} source="use_for_zonds"/>
            <BooleanInput label="Работает для постов" validate={required()} defaultValue={false} source="use_for_posts"/>
            <BooleanInput label="Конфигурируется временем" validate={required()} defaultValue={false} source="has_start_date"/>
        </SimpleForm>
    </Create>
);

export const AgroModelEdit = (props) => (
    <Edit title={"Изменить модель"} {...props}>
        <SimpleForm>
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Ссылка" validate={required()} source="link"/>
            <TextInput label="Короткое описание" validate={required()} source="short_description"/>
            <TextInput multiline label="Полное описание" validate={required()} source="long_description"/>
            <TextInput label="Текст для выключенной модели" source="disabled_text"/>
            <BooleanInput label="Включена" validate={required()} defaultValue={true} source="is_enabled"/>
            <BooleanInput label="Общая" validate={required()} defaultValue={false} source="is_common"/>
            <BooleanInput label="Работает для зондов" validate={required()} defaultValue={false} source="use_for_zonds"/>
            <BooleanInput label="Работает для постов" validate={required()} defaultValue={false} source="use_for_posts"/>
            <BooleanInput label="Конфигурируется временем" validate={required()} defaultValue={false} source="has_start_date"/>
        </SimpleForm>
    </Edit>
);

export const AgroModelShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Ссылка" source="link"/>
            <TextField label="Короткое описание" source="short_description"/>
            <TextField label="Полное описание" source="long_description"/>
            <TextField label="Текст для выключенной модели" source="disabled_text"/>
            <BooleanField label="Включена" source="is_enabled"/>
            <BooleanField label="Общая для всех" source="is_common"/>
            <BooleanField label="Работает для зондов" source="use_for_zonds"/>
            <BooleanField label="Работает для постов" source="use_for_posts"/>
            <BooleanField label="Конфигурируется временем" source="has_start_date"/>
        </SimpleShowLayout>
    </Show>
);
