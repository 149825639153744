import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


export const ZondTypeList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Тип" source="type"/>
            <TextField label="Отображаемое имя" source="displayed_name"/>
            <TextField label="Описание" source="description"/>
            <EditButton basePath="/zond_type"/>
        </Datagrid>
    </List>
);

export const ZondTypeCreate = (props) => (
    <Create title="Новый тип устройства" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Тип" validate={required()} source="type"/>
            <TextInput label="Отображаемое имя" validate={required()} source="displayed_name"/>
            <TextInput multiline label="Описание" validate={required()} source="description"/>
        </SimpleForm>
    </Create>
);

export const ZondTypeEdit = (props) => (
    <Edit title={"Изменить тип устройства"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Тип" validate={required()} source="type"/>
            <TextInput label="Отображаемое имя" validate={required()} source="displayed_name"/>
            <TextInput multiline label="Описание" validate={required()} source="description"/>
        </SimpleForm>
    </Edit>
);
