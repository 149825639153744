import {Create, Datagrid, Edit, EditButton, List, NumberField, NumberInput, SimpleForm, TextField, TextInput} from "react-admin";
import React from "react";

export const LocationRegionList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Название" source="name"/>
            <TextField label="Сокращение" source="short_name"/>
            <NumberField label="Временная зона" source="time_zone"/>
            <EditButton basePath="/location_region"/>
        </Datagrid>
    </List>
);

export const LocationRegionCreate = (props) => (
    <Create title="Новый район" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
            <TextInput label="Сокращение" source="short_name"/>
            <NumberInput label="Временная зона" defaultValue={7} source="time_zone"/>
        </SimpleForm>
    </Create>
);

export const LocationRegionEdit = (props) => (
    <Edit title={"Изменить район"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
            <TextInput label="Сокращение" source="short_name"/>
            <NumberInput label="Временная зона" source="time_zone"/>
        </SimpleForm>
    </Edit>
);
