import {Create, Datagrid, Edit, EditButton, List, NumberField, SimpleForm, TextField, TextInput} from "react-admin";
import LinkTextField from "../components/LinkTextField";
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import React from "react";

export const LocationCityList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Название" source="name"/>
            <TextField label="Сокращение" source="short_name"/>
            <LinkTextField id="location_region_id" link="location_region" label="Район" source="location_region.name"/>
            <EditButton basePath="/location_city"/>
        </Datagrid>
    </List>
);

export const LocationCityCreate = (props) => (
    <Create title="Новый населенный пункт" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
            <TextInput label="Сокращение" source="short_name"/>
            <AwaitSelectInput label="Район" source="location_region_id" optionText="name" fetchRel={"location_region"}/>
        </SimpleForm>
    </Create>
);

export const LocationCityEdit = (props) => (
    <Edit title={"Изменить населенный пункт"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
            <TextInput label="Сокращение" source="short_name"/>
            <AwaitSelectInput label="Район" source="location_region_id" optionText="name" fetchRel={"location_region"}/>
        </SimpleForm>
    </Edit>
);
