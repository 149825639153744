import React from 'react';
import {SelectArrayInput} from "react-admin";
import {apiAddress} from "../options";


export class AwaitSelectArrayInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            choices: [
                {}
            ]
        }
    }

    componentDidMount = async () => {
        await this.getData();
    }

    async getData() {
        const token = localStorage.getItem('token');
        if (token !== null && token !== undefined) {
            const request = new Request(`${apiAddress}/${this.props.fetchRel}?_start=0&_end=-1&_sort=${this.props.sortBy ? this.props.sortBy : "id"}&_order=asc`, {
                method: 'GET',
                headers: new Headers({'Authorization': `Bearer ${token}`}),
            });
            return await fetch(request)
                .then(res => res.json())
                .then((res) => {
                    this.setState({
                        choices: res
                    })
                    return res;
                })
        }
    }

    render() {
        return (
            <SelectArrayInput optionText={this.props.optionText} style={{minWidth: 200}}
                              choices={this.state.choices} optionValue={this.props.optionValue}
                              source={this.props.source} label={this.props.label}/>
        );
    }
}
