import React from 'react';

import {Admin, fetchUtils, Resource} from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';

import {apiAddress} from "./options";
import authProvider from './authProvider';

import MyLoginPage from './auth/MyLoginPage';
import MyLogoutButton from './auth/MyLogoutButton';

import {
    AgricultureAgroModelCreate,
    AgricultureAgroModelEdit,
    AgricultureAgroModelList
} from "./pages/AgricultureAgroModel";
import {AgricultureCompanyCreate, AgricultureCompanyEdit, AgricultureCompanyList} from "./pages/AgricultureCompany";
import {AgriculturePestCreate, AgriculturePestEdit, AgriculturePestList} from "./pages/AgriculturePest";
import {AgricultureCreate, AgricultureEdit, AgricultureList} from "./pages/Agriculture";
import {
    AgricultureSortCreate,
    AgricultureSortEdit,
    AgricultureSortList,
    AgricultureSortShow
} from "./pages/AgricultureSort";
import {AgroModelCreate, AgroModelEdit, AgroModelList, AgroModelShow} from "./pages/AgroModel";
import {CompanyCreate, CompanyEdit, CompanyList, CompanyShow} from './pages/Company';
import {ChartTypeCreate, ChartTypeEdit, ChartTypeList} from './pages/ChartType';
import {FirmwareCreate, FirmwareEdit, FirmwareList, FirmwareShow} from "./pages/Firmware";
import {FieldCreate, FieldEdit, FieldList, FieldShow} from "./pages/Field";
import {LocationCityCreate, LocationCityEdit, LocationCityList} from "./pages/LocationCity";
import {LocationRegionCreate, LocationRegionEdit, LocationRegionList} from "./pages/LocationRegion";
import {NotificationCreate, NotificationEdit, NotificationList} from './pages/Notification';
import {NotificationTypeCreate, NotificationTypeEdit, NotificationTypeList} from './pages/NotificationType';
import {PestCreate, PestEdit, PestList, PestShow} from "./pages/Pest";
import {UserCreate, UserEdit, UserList, UserShow} from './pages/User';
import {WikiSectionCreate, WikiSectionEdit, WikiSectionList, WikiSectionShow} from "./pages/WikiSection";
import {WikiPageCreate, WikiPageEdit, WikiPageList, WikiPageShow} from "./pages/WikiPage";
import {ZondCreate, ZondEdit, ZondList, ZondShow} from './pages/Zond';
import {ZondTypeCreate, ZondTypeEdit, ZondTypeList} from './pages/ZondType';
import {ZondMeasurementCreate, ZondMeasurementEdit, ZondMeasurementList} from './pages/ZondMeasurement';
import {ZondMeasurementTypeCreate, ZondMeasurementTypeEdit, ZondMeasurementTypeList} from './pages/ZondMeasurementType';
import {ZondCompanyCreate, ZondCompanyEdit, ZondCompanyList} from './pages/ZondCompany';
import {ZondStatusCreate, ZondStatusEdit, ZondStatusList} from './pages/ZondStatus';
import {ZondNotificationCreate, ZondNotificationEdit, ZondNotificationList} from './pages/ZondNotification';
import {ZondPhotoCreate, ZondPhotoEdit, ZondPhotoList} from "./pages/ZondPhoto";
import {ZondConfigCreate, ZondConfigEdit, ZondConfigList, ZondConfigShow} from "./pages/ZondConfig";
import {PredictionClusterCreate, PredictionClusterEdit, PredictionClusterList} from "./pages/PredictionCluster";

import polyglotI18nProvider from 'ra-i18n-polyglot';

import russianMessages from 'ra-language-russian';
import './index.css';


const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(apiAddress, httpClient);

const myDataProvider = {
    ...dataProvider,
    create: (resource, params) => {
        if (resource === 'zond_photo' && params.data.pictures) {
            return respondWithFiles(resource, params, 'pictures')
        } else if (resource === 'firmware' && params.data.firmwares) {
            return respondWithFiles(resource, params, "firmwares")
        } else {
            // fallback to the default implementation
            return dataProvider.create(resource, params);
        }
    },
};

const respondWithFiles = (resource, params, groupName) => {
    let files = [params.data[groupName]];

    return Promise.all(files.map(convertFileToBase64))
        .then(base64files =>
            base64files.map(b64 => ({
                src: b64,
                title: params.data[groupName].title,
            }))
        ).then(transformedFiles => {
                const data = {...params.data};
                data[groupName] = [...transformedFiles];
                return dataProvider.update(resource, {
                    ...params,
                    data: data
                })
            }
        );
}

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });


const App = () => (
    <Admin locale="ru" i18nProvider={i18nProvider} loginPage={MyLoginPage} logoutButton={MyLogoutButton}
           authProvider={authProvider} dataProvider={myDataProvider}>
        <Resource name="company" options={{label: 'Компании'}} list={CompanyList} create={CompanyCreate}
                  edit={CompanyEdit} show={CompanyShow}/>
        <Resource name="user" options={{label: 'Пользователи'}} list={UserList} create={UserCreate} edit={UserEdit}
                  show={UserShow}/>
        <Resource name="zond" options={{label: 'Устройства'}} list={ZondList} create={ZondCreate} edit={ZondEdit}
                  show={ZondShow}/>
        <Resource name="zond_company" options={{label: 'Устройство-Компания'}} list={ZondCompanyList}
                  create={ZondCompanyCreate} edit={ZondCompanyEdit}/>
        <Resource name="field" options={{label: 'Поля'}} list={FieldList} create={FieldCreate} edit={FieldEdit}
                  show={FieldShow}/>
        <Resource name="location_region" options={{label: 'Районы'}} list={LocationRegionList}
                  create={LocationRegionCreate} edit={LocationRegionEdit} show={FieldShow}/>
        <Resource name="location_city" options={{label: 'Населенные пункты'}} list={LocationCityList}
                  create={LocationCityCreate} edit={LocationCityEdit} show={FieldShow}/>
        <Resource name="zond_status" options={{label: 'Статусы устройств'}} list={ZondStatusList}
                  create={ZondStatusCreate} edit={ZondStatusEdit}/>
        <Resource name="zond_type" options={{label: 'Типы устройств'}} list={ZondTypeList} create={ZondTypeCreate}
                  edit={ZondTypeEdit}/>
        <Resource name="zond_config" options={{label: 'Конфигурации'}} list={ZondConfigList} create={ZondConfigCreate}
                  edit={ZondConfigEdit} show={ZondConfigShow}/>
        <Resource name="firmware" options={{label: 'Прошивки'}} list={FirmwareList} create={FirmwareCreate}
                  edit={FirmwareEdit} show={FirmwareShow}/>
        <Resource name="notification" options={{label: 'Оповещения'}} list={NotificationList}
                  create={NotificationCreate} edit={NotificationEdit}/>
        <Resource name="notification_type" options={{label: 'Типы оповещений'}} list={NotificationTypeList}
                  create={NotificationTypeCreate} edit={NotificationTypeEdit}/>
        <Resource name="zond_notification" options={{label: 'Оповещения устройств'}} list={ZondNotificationList}
                  create={ZondNotificationCreate} edit={ZondNotificationEdit}/>
        <Resource name="zond_measurement" options={{label: 'Измерения'}} list={ZondMeasurementList}
                  create={ZondMeasurementCreate} edit={ZondMeasurementEdit}/>
        <Resource name="zond_measurement_type" options={{label: 'Виды измерений'}} list={ZondMeasurementTypeList}
                  create={ZondMeasurementTypeCreate} edit={ZondMeasurementTypeEdit}/>
        <Resource name="chart_type" options={{label: 'Типы графиков'}} list={ChartTypeList} create={ChartTypeCreate}
                  edit={ChartTypeEdit}/>
        <Resource name="zond_photo" options={{label: 'Фотографии'}} list={ZondPhotoList} create={ZondPhotoCreate}
                  edit={ZondPhotoEdit}/>
        <Resource name="agriculture" options={{label: 'Культуры'}} list={AgricultureList} create={AgricultureCreate}
                  edit={AgricultureEdit}/>
        <Resource name="agriculture_sort" options={{label: 'Сорта культур'}} list={AgricultureSortList}
                  create={AgricultureSortCreate} edit={AgricultureSortEdit} show={AgricultureSortShow}/>
        <Resource name="pest" options={{label: 'Вредители'}} list={PestList} create={PestCreate}
                  edit={PestEdit} show={PestShow}/>
        <Resource name="agro_model" options={{label: 'Модели'}} list={AgroModelList} create={AgroModelCreate}
                  edit={AgroModelEdit} show={AgroModelShow}/>
        <Resource name="agriculture_agro_model" options={{label: 'Модель-Культура'}} list={AgricultureAgroModelList}
                  create={AgricultureAgroModelCreate} edit={AgricultureAgroModelEdit}/>
        <Resource name="agriculture_company" options={{label: 'Культура-Компания'}} list={AgricultureCompanyList}
                  create={AgricultureCompanyCreate} edit={AgricultureCompanyEdit}/>
        <Resource name="agriculture_pest" options={{label: 'Культура-Вредитель'}} list={AgriculturePestList}
                  create={AgriculturePestCreate} edit={AgriculturePestEdit}/>
        <Resource name="wiki_section" options={{label: 'Разделы вики'}} list={WikiSectionList}
                  create={WikiSectionCreate} edit={WikiSectionEdit} show={WikiSectionShow}/>
        <Resource name="wiki_page" options={{label: 'Страницы вики'}} list={WikiPageList}
                  create={WikiPageCreate} edit={WikiPageEdit} show={WikiPageShow}/>
        <Resource name="prediction_cluster" options={{label: 'Кластер прогнозов'}} list={PredictionClusterList}
                  create={PredictionClusterCreate} edit={PredictionClusterEdit}/>
    </Admin>
);

export default App;
