import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import LinkTextField from "../components/LinkTextField";


export const FieldList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Местоположение" source="location"/>
            <LinkTextField id="company_id" link="company" label="Компания" source="company.name"/>
            <ShowButton/>
            <EditButton basePath="/field"/>
        </Datagrid>
    </List>
);

export const FieldCreate = (props) => (
    <Create title="Новое поле" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
            <TextInput label="Местоположение" source="location"/>
            <TextInput multiline label="GEO JSON" source="geo_json" validate={required()}
                       helperText="Поле нужно выделить в Конструкторе Яндекс карт,
                           экспортировать в формате .geojson и скопировать содержимое сюда"/>
            <AwaitSelectInput label="Компания" validate={required()}
                              source="company_id" optionText="name" fetchRel={"company"}/>
        </SimpleForm>
    </Create>
);

export const FieldEdit = (props) => (
    <Edit title={"Изменить поле"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
            <TextInput label="Местоположение" source="location"/>
            <TextInput multiline label="GEO JSON" source="geo_json" validate={required()}/>
            <AwaitSelectInput label="Компания" validate={required()}
                              source="company_id" optionText="name" fetchRel={"company"}/>
        </SimpleForm>
    </Edit>
);

export const FieldShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Местоположение" source="location"/>
            <LinkTextField id="company_id" link="company" label="Компания" source="company.name"/>
            <TextField label="GEO JSON" source="geo_json"/>
        </SimpleShowLayout>
    </Show>
);
