import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from 'react-admin';

const LinkTextField = (props) => <TextField label={props.label} source={props.source}
                                            onClick={() => window.location.href = `/#/${props.link}/${props.record[props.id]}/show`}
                                            className="clickable"/>;

LinkTextField.propTypes = {
    id: PropTypes.number,
    label: PropTypes.string,
    link: PropTypes.string,
    source: PropTypes.string.isRequired,
    record: PropTypes.object,
};

LinkTextField.defaultProps = {
    addLabel: true,
}

export default LinkTextField;
