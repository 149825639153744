import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    ShowButton,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


export const PredictionClusterList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Таргет" source="target_zond"/>
            <TextField label="Выборка" source="zond_selection"/>
            <BooleanField label="Включено" source="is_enabled"/>
            <ShowButton/>
            <EditButton basePath="/prediction_cluster"/>
        </Datagrid>
    </List>
);

export const PredictionClusterCreate = (props) => (
    <Create title="Новый кластер" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Таргет" source="target_zond" validate={required()}
                       helperText="Номер зонда в БД (например, 'Z0001')"/>
            <TextInput label="Выборка" source="zond_selection" validate={required()}
                       helperText="Номера зондов для усреднения, разделённые пробелом (например, 'Z0002 Z0003 Z0004')"/>
            <NumberInput label="Вероятность шума" source="noise_probability" validate={required()}
                         helperText="Число в диапазоне от 0 до 1 (например, 0.1)"/>
            <NumberInput label="Коэффициент шума" source="noise_coefficient" validate={required()}
                         helperText="Стандартное отклонения для генерации шума (например, 0.03)"/>
            <BooleanInput label="Включено" source="is_enabled"/>
        </SimpleForm>
    </Create>
);

export const PredictionClusterEdit = (props) => (
    <Edit title={"Изменить кластер"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Таргет" source="target_zond" validate={required()}
                       helperText="Номер зонда в БД (например, 'Z0001')"/>
            <TextInput label="Выборка" source="zond_selection" validate={required()}
                       helperText="Номера зондов для усреднения, разделённые пробелом (например, 'Z0002 Z0003 Z0004')"/>
            <NumberInput label="Вероятность шума" source="noise_probability" validate={required()}
                         helperText="Число в диапазоне от 0 до 1 (например, 0.1)"/>
            <NumberInput label="Коэффициент шума" source="noise_coefficient" validate={required()}
                         helperText="Стандартное отклонения для генерации шума (например, 0.03)"/>
            <BooleanInput label="Включено" source="is_enabled"/>
        </SimpleForm>
    </Edit>
);
