import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";


export const NotificationList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Тип оповещения" source="notification_type.type"/>
            <TextField label="Название" source="title"/>
            <NumberField label="TTL" source="ttl"/>
            <BooleanField label="Включено" source="is_enabled"/>
            <BooleanField label="Административное" source="admin_only"/>
            <EditButton basePath="/notification"/>
        </Datagrid>
    </List>
);

export const NotificationCreate = (props) => (
    <Create title="Новое оповещение" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Тип оповещения" validate={required()} source="notification_type_id"
                              optionText="type" fetchRel={"notification_type"}/>
            <TextInput label="Название" validate={required()} source="title"/>
            <TextInput multiline label="Комментарий" source="comments"/>
            <NumberInput label="Время жизни (секунд)" source="ttl"
                         helperText="Минимальное время до получения оповещения такого же типа"/>
            <BooleanInput label="Включено" source="is_enabled" defaultValue={false} validate={required()}/>
            <BooleanInput label="Для администраторов" source="admin_only" defaultValue={false} validate={required()}/>
            <BooleanInput label="Отправлять на почту" source="send_email" defaultValue={false} validate={required()}/>
            <BooleanInput label="Зонды" source="use_for_zonds" defaultValue={false} validate={required()}/>
            <BooleanInput label="Посты" source="use_for_posts" defaultValue={false} validate={required()}/>
        </SimpleForm>
    </Create>
);

export const NotificationEdit = (props) => (
    <Edit title={"Изменить оповещение"} {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Тип оповещения" validate={required()} source="notification_type_id"
                              optionText="type" fetchRel={"notification_type"}/>
            <TextInput label="Название" validate={required()} source="title"/>
            <TextInput multiline label="Комментарий" source="comments"/>
            <NumberInput label="Время жизни (секунд)" source="ttl"
                         helperText="Минимальное время до получения оповещения такого же типа"/>
            <BooleanInput label="Включено" source="is_enabled" defaultValue={false} validate={required()}/>
            <BooleanInput label="Для администраторов" source="admin_only" defaultValue={false} validate={required()}/>
            <BooleanInput label="Отправлять на почту" source="send_email" defaultValue={false} validate={required()}/>
            <BooleanInput label="Зонды" source="use_for_zonds" defaultValue={false} validate={required()}/>
            <BooleanInput label="Посты" source="use_for_posts" defaultValue={false} validate={required()}/>
        </SimpleForm>
    </Edit>
);
