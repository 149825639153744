import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';


export const PestList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <ShowButton/>
            <EditButton basePath="/pest"/>
        </Datagrid>
    </List>
);

export const PestCreate = (props) => (
    <Create title="Новый вредитель" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
        </SimpleForm>
    </Create>
);

export const PestEdit = (props) => (
    <Edit title={"Изменить вредителя"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name"/>
        </SimpleForm>
    </Edit>
);

export const PestShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
        </SimpleShowLayout>
    </Show>
);
