import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {Chip} from "@material-ui/core";
import {ExtendedPagination} from "../components/ExtendedPagination";
import {AwaitSelectInput} from "../components/AwaitSelectInput";


const ZondsField = ({record = {}}) => {
    return <p>{
        record.zonds ?
            record.zonds.map((z) => {
                return <Chip onClick={() => window.location.href = `/#/zond/${z.id}`}
                             label={z["zond_type"].type + " №" + z.number} key={z.id}/>
            }) : ''}
    </p>
}
ZondsField.defaultProps = {label: 'Устройства'};

const UsersField = ({record = {}}) => {
    return <p>{
        record.users ?
            record.users.map((u) => {
                return <Chip onClick={() => window.location.href = `/#/user/${u.id}`}
                             label={`${u.surname} ${u.name}`} key={u.id}/>
            }) : ''}
    </p>
}
UsersField.defaultProps = {label: 'Пользователи'};

export const CompanyList = (props) => (
    <List {...props} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <ZondsField source="zonds"/>
            <UsersField source="users"/>
            <ShowButton/>
            <EditButton basePath="/company"/>
        </Datagrid>
    </List>
);

export const CompanyCreate = (props) => (
    <Create title="Новая компания" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name" validate={required()}/>
            <TextInput label="Телефон" source="phone"/>
            <TextInput label="Адрес" source="address"/>
            <TextInput multiline label="Описание" source="description"/>
            <AwaitSelectInput label="Район" source="location_region_id"
                              optionText="name" fetchRel={"location_region"}/>
        </SimpleForm>
    </Create>
);

export const CompanyEdit = (props) => (
    <Edit title={"Изменить компанию"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Телефон" source="phone"/>
            <TextInput label="Адрес" source="address"/>
            <TextInput multiline label="Описание" source="description"/>
            <AwaitSelectInput label="Район" source="location_region_id"
                              optionText="name" fetchRel={"location_region"}/>
        </SimpleForm>
    </Edit>
);

export const CompanyShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Телефон" source="phone"/>
            <TextField label="Адрес" source="address"/>
            <TextField label="Описание" source="description"/>
            <TextField label="Район" source="location_region.name"/>
            <ZondsField source="zonds"/>
        </SimpleShowLayout>
    </Show>
);
