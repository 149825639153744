import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';


export const ZondStatusList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Статус" source="status"/>
            <TextField label="Описание" source="description"/>
            <BooleanField label="Устройство функционирует" source="is_working"/>
            <EditButton basePath="/zond_status"/>
        </Datagrid>
    </List>
);

export const ZondStatusCreate = (props) => (
    <Create title="Новый статус зонда" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Статус" validate={required()} source="status"/>
            <TextInput label="Описание" source="description"/>
            <BooleanInput label="Устройство функционирует" source="is_working" defaultValue={false}/>
        </SimpleForm>
    </Create>
);

export const ZondStatusEdit = (props) => (
    <Edit title={"Изменить статус зонда"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Статус" validate={required()} source="status"/>
            <TextInput label="Описание" source="description"/>
            <BooleanInput label="Устройство функционирует" source="is_working" defaultValue={false}/>
        </SimpleForm>
    </Edit>
);
