import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";


export const ZondMeasurementTypeList = (props) => (
    <List {...props} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Тип измерения" source="type"/>
            <TextField label="График" source="chart_type.type"/>
            <BooleanField label="Служебный" source="admin_only"/>
            <EditButton basePath="/zond_measurement_type"/>
        </Datagrid>
    </List>
);

export const ZondMeasurementTypeCreate = (props) => (
    <Create title="Новый тип измерения" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Тип измерения" validate={required()} source="type"/>
            <AwaitSelectInput label="Тип графика" validate={required()} source="chart_type_id" optionText="type"
                              fetchRel={"chart_type"}/>
            <BooleanInput label={"Только для администратора"} defaultValue={false} source={"admin_only"}/>
        </SimpleForm>
    </Create>
);

export const ZondMeasurementTypeEdit = (props) => (
    <Edit title={"Изменить тип измерения"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Тип измерения" validate={required()} source="type"/>
            <AwaitSelectInput label="Тип графика" validate={required()} source="chart_type_id" optionText="type"
                              fetchRel={"chart_type"}/>
            <BooleanInput label={"Только для администратора"} defaultValue={false} source={"admin_only"}/>
        </SimpleForm>
    </Edit>
);
