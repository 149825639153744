import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    List,
    required,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";
import LinkTextField from "../components/LinkTextField";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск по компании" source="company_name" alwaysOn/>
        <TextInput label="Поиск по устройству" source="zond_number" alwaysOn/>
    </Filter>
);

export const ZondCompanyList = (props) => (
    <List {...props} filters={<Filters/>} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Тип устройства" source="zond.zond_type.type"/>
            <LinkTextField id="zond_id" link="zond" label="Номер устройства" source="zond.number"/>
            <LinkTextField id="company_id" link="company" label="Компания" source="company.name"/>
            <EditButton basePath="/zond_company"/>
        </Datagrid>
    </List>
);

export const ZondCompanyCreate = (props) => (
    <Create title="Связать устройство и компанию" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Устройство" validate={required()} source="zond_id" fetchRel={"zond"}
                              optionText={(record) => {
                                  return record["zond_type"] === undefined ?
                                      '' : record["zond_type"]["type"] + " №" + record["number"]
                              }} sortBy={"number_in_db"}/>
            <AwaitSelectInput label="Компания" validate={required()} source="company_id" optionText="name"
                              fetchRel={"company"}/>
        </SimpleForm>
    </Create>
);

export const ZondCompanyEdit = (props) => (
    <Edit title={"Изменить связь"} {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Устройство" validate={required()} source="zond_id" fetchRel={"zond"}
                              optionText={(record) => {
                                  return record["zond_type"] === undefined ?
                                      '' : record["zond_type"]["type"] + " №" + record["number"]
                              }}/>
            <AwaitSelectInput label="Компания" validate={required()} source="company_id" optionText="name"
                              fetchRel={"company"}/>
        </SimpleForm>
    </Edit>
);
