import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput,
} from 'react-admin';
import {Chip} from "@material-ui/core";

const CompaniesField = ({record = {}}) => {
    return <p>{
        record.companies ?
            record.companies.map((c) => {
                return <Chip onClick={() => window.location.href = `/#/company/${c.id}`} label={c.name} key={c.id}/>
            }) : ''}
    </p>
}
CompaniesField.defaultProps = {label: 'Компании'};

const AgricultureSortsField = ({record = {}}) => {
    return <p>{
        record.agriculture_sorts ?
            record.agriculture_sorts.map((as) => {
                return <Chip onClick={() => window.location.href = `/#/agriculture_sort/${as.id}`} label={as.name} key={as.id}/>
            }) : ''}
    </p>
}
AgricultureSortsField.defaultProps = {label: 'Сорта культуры'};


export const AgricultureList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <TextField label="Название" source="name"/>
            <TextField label="Описание" source="description"/>
            <CompaniesField source="companies"/>
            <AgricultureSortsField source="agriculture_sorts"/>
            <EditButton basePath="/agriculture"/>
        </Datagrid>
    </List>
);

export const AgricultureCreate = (props) => (
    <Create title="Новая культура" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Описание" validate={required()} source="description"/>
        </SimpleForm>
    </Create>
);

export const AgricultureEdit = (props) => (
    <Edit title={"Изменить культуру"} {...props}>
        <SimpleForm>
            <TextInput label="Название" validate={required()} source="name"/>
            <TextInput label="Описание" validate={required()} source="description"/>
        </SimpleForm>
    </Edit>
);
