import React from 'react';
import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    List,
    NumberField,
    NumberInput,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';


export const ZondConfigList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <ShowButton/>
            <EditButton basePath="/zond_config"/>
        </Datagrid>
    </List>
);

export const ZondConfigCreate = (props) => (
    <Create title="Новая конфигурация" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" validate={required()} source="name"/>
            <NumberInput label="Период сбора данных" validate={required()}
                         helperText="В часах для старых версий, в секундах для новых!" source="check_period"/>
            <NumberInput label="Период отправки данных" validate={required()}
                         helperText="В часах для старых версий, в секундах для новых!" source="send_period"/>
            <BooleanInput label="Летний период" defaultValue={true} source="is_summer"/>
        </SimpleForm>
    </Create>
);

export const ZondConfigEdit = (props) => (
    <Edit title={"Изменить конфигурацию"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" validate={required()} source="name"/>
            <NumberInput label="Период сбора данных" validate={required()}
                         helperText="В часах для старых версий, в секундах для новых!" source="check_period"/>
            <NumberInput label="Период отправки данных" validate={required()}
                         helperText="В часах для старых версий, в секундах для новых!" source="send_period"/>
            <BooleanInput label="Летний период" source="is_summer"/>
        </SimpleForm>
    </Edit>
);

export const ZondConfigShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создан" source="created_at"/>
            <NumberField label="Период сбора данных" source="check_period"/>
            <NumberField label="Период отправки данных" source="send_period"/>
            <BooleanField label="Летний период" source="is_summer"/>
        </SimpleShowLayout>
    </Show>
);

