import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    Filter,
    List,
    NumberField,
    NumberInput,
    required,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput
} from 'react-admin';
import {Chip} from '@material-ui/core';

import {AwaitSelectInput} from "../components/AwaitSelectInput";
import {ExtendedPagination} from "../components/ExtendedPagination";
import LinkTextField from "../components/LinkTextField";

const Filters = (props) => (
    <Filter {...props}>
        <TextInput label="Поиск по компании" source="company_name" alwaysOn/>
        <TextInput label="Поиск по устройству" source="zond_number" alwaysOn/>
    </Filter>
);

const CompaniesField = ({record = {}}) => {
    return <p>{
        record.companies ?
            record.companies.map((c) => {
                return <Chip onClick={() => window.location.href = `/#/company/${c.id}`}
                             label={c.name} key={c.id}/>
            }) : ''}
    </p>
}
CompaniesField.defaultProps = {label: 'Компании'};

export const ZondList = (props) => (
    <List {...props} filters={<Filters/>} perPage={25} pagination={<ExtendedPagination/>}>
        <Datagrid>
            <DateField label="Обновлено" source="updated_at"/>
            <LinkTextField id="zond_type_id" link="zond_type" label="Тип" source="zond_type.type"/>
            <TextField label="Номер" source="number"/>
            <CompaniesField label="Компании" source="companies"/>
            <TextField label="Статус" source="zond_status.status"/>
            <DateField label="Последние данные" source="last_data_sent"/>
            <NumberField label="Текущая прошивка" source="current_firmware_version"/>
            <ShowButton/>
            <EditButton basePath="/zond"/>
        </Datagrid>
    </List>
);

export const ZondCreate = (props) => (
    <Create title="Новая метеостанция" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Номер" source="number" validate={required()}/>
            <TextInput label="Номер в БД" source="number_in_db" validate={required()}
                       helperText="В формате Z**** для зонда и P**** для поста, где **** - номер устройства"/>
            <TextInput multiline label="Описание (заметки)" source="description"/>
            <TextInput label="Место (детали)" source="place"/>
            <AwaitSelectInput label="Район" source="location_region_id" optionText="name"
                              fetchRel={"location_region"}/>
            <AwaitSelectInput label="Посёлок" source="location_city_id" optionText="name"
                              fetchRel={"location_city"}/>
            <NumberInput label="Широта (latitude)" source="latitude"/>
            <NumberInput label="Долгота (longitude)" source="longitude"/>
            <NumberInput label="Высота (altitude)" source="altitude"/>
            <AwaitSelectInput label="Тип" validate={required()} source="zond_type_id" optionText="type"
                              fetchRel={"zond_type"}/>
            <AwaitSelectInput label="Статус" validate={required()} source="zond_status_id" optionText="status"
                              fetchRel={"zond_status"}
                              helperText="Для новых устройств желательно выбирать статус 'Готов к запуску'"/>
            <AwaitSelectInput label="Конфиг" validate={required()} source="zond_config_id" optionText="name"
                              fetchRel={"zond_config"}/>
            <AwaitSelectInput label="Прошивка" source="firmware_id" optionText="name" fetchRel={"firmware"}/>
            <TextInput label="Номер телефона" source="number_sim"/>
            <TextInput label="Оператор" source="operator_sim"/>
            <NumberInput label="Поправка на ветер" validate={required()} initialValue={0} source="wind_amendment"
                         helperText="Актуально для метеопостов"/>
            <NumberInput label="Таймзона" initialValue={7} source="timezone"/>
            <TextInput label="Отключенные датчики" source="disabled_sensors"
                       helperText="Перечисление датчиков (t, hm, pr, wd, wv, Wmax, far, rn, soil1, soil2, soil3, t0. ..., t8) через запятую"/>
        </SimpleForm>
    </Create>
);

export const ZondEdit = (props) => (
    <Edit title={"Изменить метеостанцию"} {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Номер" source="number" validate={required()}/>
            <TextInput label="Номер в БД" source="number_in_db" validate={required()}/>
            <TextInput multiline label="Описание (заметки)" source="description"/>
            <TextInput label="Место (детали)" source="place"/>
            <AwaitSelectInput label="Район" source="location_region_id" optionText="name"
                              fetchRel={"location_region"}/>
            <AwaitSelectInput label="Посёлок" source="location_city_id" optionText="name"
                              fetchRel={"location_city"}/>
            <NumberInput label="Широта (latitude)" source="latitude"/>
            <NumberInput label="Долгота (longitude)" source="longitude"/>
            <NumberInput label="Высота (altitude)" source="altitude"/>
            <AwaitSelectInput label="Тип" validate={required()} source="zond_type_id" optionText="type"
                              fetchRel={"zond_type"}/>
            <AwaitSelectInput label="Статус" validate={required()} source="zond_status_id" optionText="status"
                              fetchRel={"zond_status"}/>
            <AwaitSelectInput label="Конфиг" validate={required()} source="zond_config_id" optionText="name"
                              fetchRel={"zond_config"}/>
            <AwaitSelectInput label="Прошивка" source="firmware_id" optionText="name" fetchRel={"firmware"}/>
            <br/>
            <TextInput label="Номер телефона" source="number_sim"/>
            <TextInput label="Оператор" source="operator_sim"/>
            <NumberInput label="Поправка на ветер" validate={required()} source="wind_amendment"/>
            <NumberInput label="Таймзона" source="timezone"/>
            <TextInput label="Отключенные датчики" source="disabled_sensors"
                       helperText="Перечисление датчиков (t, hm, pr, wd, wv, Wmax, far, rn, soil1, soil2, soil3, t0. ..., t8) через запятую"/>
        </SimpleForm>
    </Edit>
);

export const ZondShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создан" source="created_at"/>
            <TextField label="Номер" source="number"/>
            <TextField label="Номер в БД" source="number_in_db"/>
            <LinkTextField id="zond_type_id" link="zond_type" label="Тип" source="zond_type.type"/>
            <TextField label="Описание" source="description"/>
            <TextField label="Место" source="place"/>
            <LinkTextField id="location_region_id" link="location_region" label="Район" source="location_region.name"/>
            <LinkTextField id="location_city_id" link="location_city" label="Посёлок" source="location_city.name"/>
            <TextField label="Широта (latitude)" source="latitude"/>
            <TextField label="Долгота (longitude)" source="longitude"/>
            <TextField label="Высота (altitude)" source="altitude"/>
            <TextField label="Таймзона" source="timezone"/>
            <br/>
            <TextField label="Статус" source="zond_status.status"/>
            <LinkTextField id="zond_config_id" link="zond_config" label="Конфиг" source="zond_config.name"/>
            <TextField id="firmware_id" link="firmware" label="Прошивка" source="firmware.name"/>
            <br/>
            <TextField label="Номер телефона" source="number_sim"/>
            <TextField label="Оператор" source="operator_sim"/>
            <NumberField label="Поправка на ветер" source="wind_amendment"/>
            <br/>
            <TextField label="Отключенные датчики" source="disabled_sensors"/>
            <DateField label="Последние данные" showTime={true} source="last_data_sent"/>
            <NumberField label="Текущая версия прошивки" source="current_firmware_version"/>
            <CompaniesField label="Компании" source="companies"/>
        </SimpleShowLayout>
    </Show>
);

