import React from 'react';
import PropTypes from 'prop-types';
import {staticAddress} from "../options";

const MyImageField = ({source, record = {}}) => <img alt={'=('} height={"auto"} width={300}
                                                     src={staticAddress + "/photos/" + record[source]}/>;

MyImageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default MyImageField;
