import React from 'react';
import {
    Create,
    Datagrid,
    DateField,
    Edit,
    EditButton,
    FileField,
    FileInput,
    List,
    NumberField,
    Show,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
} from 'react-admin';
import {Chip} from "@material-ui/core";
import {AwaitSelectArrayInput} from "../components/AwaitSelectArrayInput";

const ZondsField = ({record = {}}) => {
    return <p>{
        record.zonds ?
            record.zonds.map((c) => {
                return <Chip onClick={() => window.location.href = `/#/zond/${c.id}`}
                             label={"№" + c.number} key={c.id}/>
            }) : ''}
    </p>
}
ZondsField.defaultProps = {label: 'Устройства'};

export const FirmwareList = (props) => (
    <List {...props}>
        <Datagrid>
            <DateField label="Создано" source="created_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Полный путь" source="file_path"/>
            <ShowButton/>
            <EditButton basePath="/firmware"/>
        </Datagrid>
    </List>
);

export const FirmwareCreate = (props) => (
    <Create title="Новая прошивка" {...props}>
        <SimpleForm redirect="list">
            <TextInput label="Название" source="name" required={true}
                       helperText="В формате P-0000-0.bin"/>
            <TextInput multiline label="Описание" source="description"
                       helperText="Что нового в данной версии или для каких устройств предназначается"/>
            <FileInput source="firmwares" label="Бинарный файл" required={true}
                       helperText="Название и файл в дальнейшем нельзя будет изменить!">
                <FileField source="src" title="title"/>
            </FileInput>
            <AwaitSelectArrayInput label="Устройства" source="zond_ids" optionText="number_in_db" sortBy="number_in_db"
                                   fetchRel="zond"/>
        </SimpleForm>
    </Create>
);

export const FirmwareEdit = (props) => (
    <Edit title={"Изменить прошивку"} {...props}>
        <SimpleForm redirect="list">
            <TextInput multiline label="Описание" source="description"/>
        </SimpleForm>
    </Edit>
);

export const FirmwareShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField label="ID" source="id"/>
            <DateField label="Создано" source="created_at"/>
            <DateField label="Обновлено" source="updated_at"/>
            <TextField label="Название" source="name"/>
            <TextField label="Описание" source="description"/>
            <TextField label="Путь к файлу" source="file_path"/>
            <br/>
            <ZondsField label="Устройства" source="zonds"/>
        </SimpleShowLayout>
    </Show>
);
