import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    ImageField,
    ImageInput,
    List,
    NumberField,
    required,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';
import {AwaitSelectInput} from "../components/AwaitSelectInput";
import MyImageField from "../components/MyImageField";
import LinkTextField from "../components/LinkTextField";


export const ZondPhotoList = (props) => (
    <List {...props}>
        <Datagrid>
            <NumberField label="ID" source="id"/>
            <LinkTextField id="zond_id" link="zond" label="Номер устройства" source="zond.number"/>
            <TextField label="Имя файла" source="file_path"/>
            <MyImageField label="Фото" source={"file_path"}/>
            <EditButton basePath="/zond_photo"/>
        </Datagrid>
    </List>
);

export const ZondPhotoCreate = (props) => (
    <Create title="Новое фото для устройства" {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Номер устройства" validate={required()} source="zond_id" fetchRel={"zond"}
                              optionText={(record) => {
                                  return record["zond_type"] === undefined ?
                                      '' : record["zond_type"]["type"] + " №" + record["number"]
                              }}
            />
            <ImageInput source="pictures" label="Загрузка фотографии" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
        </SimpleForm>
    </Create>
);

export const ZondPhotoEdit = (props) => (
    <Edit title={"Изменить фото устройства"} {...props}>
        <SimpleForm redirect="list">
            <AwaitSelectInput label="Номер устройства" validate={required()} source="zond_id" optionText="number"
                              fetchRel={"zond"}/>
            <TextInput label="Путь к файлу" source="file_path" validate={required()}/>
        </SimpleForm>
    </Edit>
);
